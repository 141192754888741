import Header from "../components/header";
import {useEffect, useState} from "react";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {db, storage} from "../firebase";
import {useNavigate} from "react-router";
import {useDropzone} from "react-dropzone";
import {v4 as uuidv4} from 'uuid';

export default function Index() {
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [canCreate, setCanCreate] = useState(false);
    const [counter, setCounter] = useState(0);
    const {getRootProps, getInputProps, open} = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
            'image/svg': [],
            'image/gif': []
        },
        preventDropOnDocument: true,
        maxSize: 1024 * 1024 * 10,
        maxFiles: 50,
        onDropAccepted: (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                // create an option
                addOption('image', {
                    image: file
                })
            })
        },
        onDropRejected: () => {
            alert('One or more of your files was rejected. Please upload images less than 10mb');
        }
    });

    let navigate = useNavigate();

    function handleQuestionChange(e) {
        setQuestion(e.target.value);
    }

    useEffect(() => {
        let counter = 0;
        document.addEventListener('dragenter', (e) => {
            e.preventDefault();
            counter++;
            document.body.classList.add('dragging');
        });

        function dragLeave(e) {
            e.preventDefault()

            counter--;

            if(counter === 0) {
                document.body.classList.remove('dragging');
            }
        }

        document.addEventListener('dragleave', dragLeave);
       // document.addEventListener('dragend', dragLeave);
        document.addEventListener('drop', dragLeave);
    }, []);

    function addOption(type, additionalOptions = {}) {
        const newId = uuidv4();
        const index = options.length;

        if(type === 'image') {
            // start uploading that image now

            // set progress to zero
            setUploadProgress(prevUploadProgress => {
                prevUploadProgress[newId] = 0;
                return prevUploadProgress;
            });

            const rand = uuidv4();
            const imageName = `/files/${rand}/${additionalOptions.image.name}`;
            const storageRef = ref(storage, imageName);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, additionalOptions.image);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setUploadProgress(prevUploadProgress => {
                        prevUploadProgress[newId] = percent;
                        return prevUploadProgress;
                    });

                    setCounter(counter => counter + 1);
                },
                (err) => console.log(err),
                (() => {
                    return () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            handleOptionChange(index, url);

                            setOptions((oldOptions) => {
                                for(let i = 0; i < oldOptions.length; i++) {
                                    if(oldOptions[i].id === newId) {
                                        oldOptions[i].label = url;
                                    }
                                }

                                return oldOptions;
                            });

                            setUploadProgress(prevUploadProgress => {
                                prevUploadProgress[newId] = 100;
                                return prevUploadProgress;
                            });

                            setCounter(counter => counter + 1);
                        });
                    }
                })()
            );

            additionalOptions['previewUrl'] = URL.createObjectURL(additionalOptions.image);
        }

        if(additionalOptions.hasOwnProperty('image')) {
            delete additionalOptions['image'];
        }

        setOptions(oldOptions => [...oldOptions, {
            ...additionalOptions,
            id: newId,
            type,
            label: ''
        }]);
    }

    function handleOptionChange(index, label) {
        setOptions((oldOptions) => {
            oldOptions[index].label = label;
            setCounter(counter => counter + 1);
            return oldOptions;
        })
    }

    function deleteOption(id) {
        setOptions((options) => {
            return options.filter((option) => {
                return option.id !== id;
            });
        });

        setUploadProgress(oldUploadProgress => {
            delete oldUploadProgress[id];
            return oldUploadProgress;
        })
    }

    useEffect(() => {
        setCanCreate(options.filter((option) => {
            return option.label && option.label.length > 0;
        }).length > 0 && question && question.length > 0);
    }, [options, question, counter]);

    function create(e) {
        e.preventDefault();

        addDoc(collection(db, "polls"), {
            question,
            options
        }).then((docRef) => {
            navigate('/' + docRef.id);
        }).catch((e) => {
            console.error(e);
        })

        return false;
    }

    useEffect(() => {
        document.title = 'this or that?';
    }, []);

    return (
        <>
            <Header />
            <h1>create simple polls for free</h1>
            <form id="create-poll" data-counter={counter} onSubmit={create}>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>Drag and drop images here</p>
                </div>

                <dl>
                    <dd>
                        <input type="text" id="question" name="question" placeholder="your question here" autoFocus onChange={handleQuestionChange} />
                    </dd>
                </dl>

                <dl>
                    <dd>
                        <ul id="options">
                            {options.map((option, index) => {
                                return (
                                    <li key={option.id.toString()} className={`option-${option.type}`}>
                                        {
                                            option.type === 'text' ? <input autoFocus placeholder="your option" defaultValue={option.label} onChange={(e) => { handleOptionChange(index, e.target.value)}} /> : ''
                                        }

                                        {
                                            option.type === 'image' ? (<>
                                                <div className="image">
                                                    <div className="image-progress">
                                                        <img src={option.previewUrl} alt="" />
                                                        <div id={`upload-progress-${option.id}`} className={`image-upload-progress ${uploadProgress[option.id] === 100 ? 'complete' : 'incomplete'}`}>
                                                            <div className="image-upload-progress-bar" style={{width: (100 - uploadProgress[option.id]) + '%'}} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>) : ''
                                        }

                                        <button className="delete-option" onClick={() => deleteOption(option.id)}>❌</button>
                                    </li>
                                )
                            })}
                        </ul>

                        <div className="add-options">
                            <button className="add-option" onClick={(e) => { e.preventDefault(); addOption('text', true) }}>+ option</button>
                            <button className="add-option" onClick={(e) => { e.preventDefault(); open() }}>+ image</button>
                        </div>
                    </dd>
                </dl>

                <button type="submit" disabled={!canCreate}>create poll</button>
            </form>
        </>
    )
}