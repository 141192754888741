import {useLoaderData, useNavigate} from "react-router";
import {db} from "../firebase";
import {onSnapshot, doc, updateDoc} from "firebase/firestore";
import {useEffect, useState} from "react";
import Header from "./header";
import {RWebShare} from "react-web-share";

export async function loader({params}) {
    return params.pollId;
}

export default function Poll() {
    const [poll, setPoll] = useState({});
    const pollId = useLoaderData();
    const [selectedOption, setSelectedOption] = useState(localStorage.getItem(pollId) || null);
    const navigate = useNavigate();

    function chooseOption(id) {
        if(selectedOption === null) {
            const ref = doc(db, "polls", pollId);
            let votes = getVotes(id);
            votes++;
            updateDoc(ref, {
                [`votes.${id}`]: votes,
            }, {
                merge: true
            });

            localStorage.setItem(pollId, id);
            setSelectedOption(id);
        }
    }

    function removeVote() {
        if(selectedOption !== null) {
            const id = selectedOption;
            const ref = doc(db, "polls", pollId);
            let votes = getVotes(id);
            votes--;
            updateDoc(ref, {
                [`votes.${id}`]: votes,
            }, {
                merge: true
            });

            localStorage.removeItem(pollId);
            setSelectedOption(null);
        }
    }

    function getVotes(id) {
        return poll.votes ? (poll.votes[id] || 0) : 0;
    }

    function getWidth(id) {
        if(!poll.votes) {
            return 0;
        }

        // get the total number of votes across all options
        const totalVotes = Object.values(poll.votes).reduce((prev, next) => {
            return prev + next;
        });

        const width = getVotes(id) / totalVotes * 100;

        if(isNaN(width)) {
            return 0;
        }

        return Math.round(width, 1);
    }

    function createPoll() {
        navigate('/');
    }

    useEffect(() => {
        async function fetchData() {
            onSnapshot(doc(db, "polls", pollId), (doc) => {
                const data = doc.data();
                setPoll(data);
                document.title = data.question + ' | this or that?';
            })
        }
        fetchData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header />
            <section id="poll">
                <h1>{poll.question}</h1>
                <ul>
                    {poll.options?.map((option, index) => {
                        return (
                            <li key={option.id}>
                                <button className={`option ${selectedOption !== null ? 'voted' : ''} ${selectedOption === option.id ? 'selected' : ''}`} onClick={() => { chooseOption(option.id)}}>
                                    <div className="progress-bar" style={{width: selectedOption === null ? 0 : getWidth(option.id) + '%'}} />
                                    {selectedOption !== null ? (
                                        <>
                                            <div className="votes">{getVotes(option.id)} vote{getVotes(option.id) === 1 ? '' : 's'}, {getWidth(option.id)}%</div>
                                        </>
                                    ): ''}
                                    <span className="option-label">
                                        {option.type === 'image' ? (
                                            <img src={option.label} loading="lazy" alt="" />
                                        ) : option.label }

                                        {selectedOption === option.id ? '✅' : ''}
                                    </span>
                                </button>
                            </li>
                        )
                    })}
                </ul>

                {selectedOption !== null ? <button className="remove-vote" onClick={removeVote}>Remove my vote ❌</button> : ''}
            </section>

            <aside className="meta">
                <RWebShare data={{
                    text: poll.question,
                    title: 'this or that?'
                }}>
                    <button class="share-button">share this 🔗</button>
                </RWebShare>

                <button class="create-poll" onClick={createPoll}>create poll 🗳️</button>
            </aside>
        </>
    )
}