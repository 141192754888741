// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCSwEBJFRrAhx7l3qrDjiY2Djej4yTsow4",
    authDomain: "consensio-c2d5d.firebaseapp.com",
    projectId: "consensio-c2d5d",
    storageBucket: "consensio-c2d5d.appspot.com",
    messagingSenderId: "973731045456",
    appId: "1:973731045456:web:ed91fa3b1597fc92947155",
    measurementId: "G-72BHWJWP7X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); // eslint-disable-line no-unused-vars
export const db = getFirestore(app);
export const storage = getStorage(app);